// import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { Loader } from 'components/loader/loader'
// import { useAuthState } from 'context'
import { SmartCaptcha } from '@yandex/smart-captcha'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { validationCallback } from 'components/forms/validation.js'
import { SuccessCallback } from 'components/modals/SuccessCallback'
import FormDot from 'assets/images/content/bottom/form_dot.svg'
import VoteDot from 'assets/images/content/bottom/vote_dot.svg'

import './Bottom.scss'

export const Bottom = () => {
  // const { token } = useAuthState()
  const [isLoading, setIsLoading] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)
  const [yatoken, setYatoken] = useState('')
  const [errorSend, setErrorSend] = useState('')

  useEffect(() => {
    if (yatoken) {
      setErrorSend('')
    }
  }, [yatoken])

  return (
    <section id="bottom" className="bottom">
      <div className="container">
        <div className="bottom__wrap">
          <div className="bottom__form">
            <img src={FormDot} alt="" className="bottom__form_dot" />
            <div className="bottom__form_title">Обратная связь</div>
            <Formik
              initialValues={{ name: '', mail: '', comment: '' }}
              validate={(values) => {
                return validationCallback(values)
              }}
              onSubmit={async (values) => {
                setIsLoading(true)
                if (yatoken) {
                  return axios
                    .post(`${process.env.REACT_APP_BACKEND}/feed-back/store`, {
                      name: values.name,
                      email: values.mail,
                      content: values.comment,
                    })
                    .then((data) => {
                      values.name = ''
                      values.mail = ''
                      values.comment = ''
                      setSuccessOpen(true)
                    })
                    .catch((e) => {
                      setErrorSend('Что то пошло не так...')
                    })
                    .finally(() => setIsLoading(false))
                } else {
                  setErrorSend('Ошибка проверки капчи')
                  setIsLoading(false)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
              }) => (
                <Form
                  className="callback__form callback__form_2 js-callback-form"
                  onSubmit={handleSubmit}
                >
                  <div className="callback__input-group">
                    <div className="callback__input-block">
                      <label htmlFor="name">Ваше имя</label>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Ваше имя"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <span className="callback__error">
                        {errors.name && touched.name && errors.name}
                      </span>
                    </div>
                    <div className="callback__input-block">
                      <label htmlFor="mail">Ваш e-mail</label>
                      <Field
                        id="mail"
                        name="mail"
                        type="text"
                        placeholder="Ваш e-mail"
                        onChange={handleChange}
                        value={values.mail}
                      />
                      <span className="callback__error">
                        {errors.mail && touched.mail && errors.mail}
                      </span>
                    </div>
                  </div>
                  <div className="callback__input-group">
                    <div className="callback__input-block w-full">
                      <label htmlFor="comment">Ваше обращение</label>
                      <Field
                        id="comment"
                        name="comment"
                        type="textarea"
                        component="textarea"
                        placeholder="Ваше обращение"
                        onChange={handleChange}
                        value={values.comment}
                      />
                      <span className="callback__error">
                        {errors.comment && touched.comment && errors.comment}
                      </span>
                    </div>
                  </div>
                  <div className="callback__input-group">
                    <button
                      className="callback__btn-submit button"
                      type="submit"
                      disabled={!isValid || isLoading}
                    >
                      {isLoading && <Loader />}
                      <span style={{ opacity: isLoading ? 0 : 1 }}>
                        Направить обращение
                      </span>
                      {errorSend.length > 0 && (
                        <span className="callback__error">{errorSend}</span>
                      )}
                    </button>
                    <div className="callback__descr">
                      По техническим вопросам:{' '}
                      <a href="mailto:cpgi@edinros.ru">cpgi@edinros.ru</a>
                    </div>
                    <SmartCaptcha
                      sitekey={process.env.REACT_APP_YANDEX_CAPTCHA}
                      onSuccess={setYatoken}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="bottom__vote">
            <img src={VoteDot} alt="" className="bottom__vote_dot" />
            <div className="bottom__vote_title">
            Всероссийский конкурс поддержки гражданских инициатив

            </div>
            <div className="bottom__vote_descr">
            Для НКО и лидеров общественных проектов

            </div>
            <div className="bottom__vote_text">
            Победители получат Всероссийское признание, помощь в реализации инициатив и возможность стать кандидатом в депутаты от самой сильной партии страны»

            </div>
            <div className="bottom__btn-flex">
              {/* <Link
                to={
                  token
                    ? '/vote'
                    : {
                        pathname: '/login',
                        query: { vote_redir: true },
                      }
                }
              >
                <button className="top__btn button button_white">
                  Подать заявку
                </button>
              </Link> */}
              {/* {true && (
                <Link to="/voting">
                  <button className="top__btn button">Проголосовать</button>
                </Link>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <SuccessCallback isOpen={successOpen} setIsOpen={setSuccessOpen} />
    </section>
  )
}
